<template>
  <div class="row q-mb-xl justify-between">
    <div class="text-h4">{{ title }}</div>
    <div class="q-ml-xl" v-if="buttonText">
      <q-btn @click="$emit('update:action', true)" color="primary">{{ buttonText }}</q-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBasic',
  props: ['title', 'buttonText']
}
</script>
