<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">

      <!--
            DEPARTMENTS
        -->
      <HeaderBasic title="User Groups" buttonText="New User Group" :action.sync="departmentModal" />
      <div class="row q-mt-xl block">
        <div class="text-h6">Select a User Group</div>
      </div>
      <div class="row gutter-md q-mt-sm">
        <div class="col-6">
          <filter-select :options="$store.getters['departments/list']" v-bind:selection.sync="department" label="Select User Group" />
        </div>
        <div class="col-3 q-ml-md">
          <q-btn v-if="department" class="q-ma-sm" flat round dense icon="delete" @click="deleteDepartment()" :loading="deletingDepartment" />
        </div>
      </div>
      <q-dialog v-model="departmentModal">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="addDepartment" @reset="departmentModal = false" autocomplete="off">
            <q-card-section>
              <q-input id="depTitle" v-model.trim="departmentTitle" label="User Group Name" :rules="formRules.title" />
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="addingDepartment" color="primary">Add</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>
      <q-separator class="q-my-xl"/>

      <!--
            LOCATION TYPES
        -->
      <HeaderBasic title="Location Types" buttonText="New Location Type" :action.sync="locationTypeModal" />
      <div class="row q-mt-xl block">
        <div class="text-h6">Select a Location Type</div>
      </div>
      <div class="row gutter-md q-mt-sm">
        <div class="col-6">
          <filter-select :options="$store.getters['locationTypes/list']" v-bind:selection.sync="locationType" label="Select Location Type" />
        </div>
        <div class="col-3 q-ml-md">
          <q-btn v-if="locationType" class="q-ma-sm" flat round dense icon="delete" @click="deleteLocationType()" :loading="deletingLocationType" />
        </div>
      </div>
      <q-dialog v-model="locationTypeModal">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="addLocationType" @reset="locationTypeModal = false" autocomplete="off">
            <q-card-section>
              <q-input v-model.trim="locationTypeTitle" label="Location Title" :rules="formRules.title" />
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="addingLocationType" color="primary">Add</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>
      <q-separator class="q-my-xl"/>

      <!--
            LOCATIONS
        -->
      <HeaderBasic title="Locations" buttonText="New Location" :action.sync="locationModal" />
      <div class="row q-mt-xl block">
        <div class="text-h6">Select a Location to Edit</div>
      </div>
      <div class="row gutter-md q-mt-sm">
        <div class="col-6">
          <filter-select :options="$store.getters['locations/list']" v-bind:selection.sync="editLocation" label="Select Location" />
        </div>
      </div>

      <div class="row q-my-xl" v-if="editLocation" id="editLocation">
        <div class="col-6">
          <q-card flat class="bg-lmsBackground primaryText-text">
            <q-form @submit="updateLocation" @reset="editLocation = null" autocomplete="off">
              <q-card-section class="row">
                <div class="text-h6 col-6">Edit Location</div>
                <!-- <div class="col-6">
                  <q-toggle v-model="location.is_active" label="Is Active" color="positive" class="float-right"/>
                </div> -->
              </q-card-section>
              <q-card-section class="col-6">
                <div class="q-mb-lg">
                  <span class="text-subtitle2">Location Name</span>
                  <q-input label="Name" type="text" max-length="49" v-model.trim="location.name" :rules="formRules.name" />
                </div>
                <div class="q-mb-lg">
                  <q-input label="City" type="text" max-length="49" v-model.trim="location.city" :rules="formRules.city" />
                </div>
                <div class="q-mb-lg">
                  <filter-select :options="states" v-bind:selection.sync="location.state" label="State / Territory" :rules="formRules.state" />
                </div>
                <div class="q-mb-lg" v-if="location.uid !== $store.getters['auth/user'].company.uid">
                  <q-select emit-value map-options label="Parent Location" v-model.trim="location.parent_uid" :options="$store.getters['locations/listAll']" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.parentLocation" />
                </div>
                <div class="q-mb-lg">
                  <q-select emit-value map-options label="Location Type" v-model.trim="location.nest_type_uid" :options="$store.getters['locationTypes/list']" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.locationType" />
                </div>
              </q-card-section>
              <div class="row gutter-xs q-mt-lg justify-between">
                <div class="col">
                  <q-btn flat @click="deleteLocation(location.uid)" :loading="deletingLocation" v-if="location.uid !== $store.getters['auth/user'].company.uid">Delete</q-btn>
                </div>
                <div class="col-12">
                  <q-btn type="submit" class="float-right" :loading="savingLocation" color="primary">Update</q-btn>
                  <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
                </div>
              </div>
            </q-form>
          </q-card>
        </div>
      </div>

      <q-dialog v-model="locationModal">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="addLocation" @reset="locationModal = false" autocomplete="no">
            <q-card-section>
              <div class="col-xs-12 col-md-4 col-xl-2 q-pa-sm">
                <q-input label="Name" type="text" max-length="49" v-model.trim="location.name" :rules="formRules.name" autocomplete="no"/>
              </div>
              <div class="col-xs-12 col-md-4 col-xl-2 q-pa-sm">
                <q-input label="City" type="text" max-length="49" v-model.trim="location.city" :rules="formRules.city" autocomplete="no"/>
              </div>
              <div class="col-xs-12 col-md-4 col-xl-2 q-pa-sm">
                <filter-select :options="states" v-bind:selection.sync="location.state" label="State / Territory" :rules="formRules.state" autocomplete="no"/>
              </div>
              <div class="col-xs-12 col-md-5 col-xl-2 q-mt-xl-tab q-pa-sm">
                <q-select clearable emit-value map-options label="Parent Location" v-model.trim="location.parent_uid" :options="$store.getters['locations/listAll']" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.parentLocation" autocomplete="no"/>              </div>
              <div class="col-xs-9 col-md-5 col-xl-2 q-mt-xl-tab q-pa-sm">
                <q-select clearable emit-value map-options label="Location Type" v-model.trim="location.nest_type_uid" :options="$store.getters['locationTypes/list']" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.locationType" autocomplete="no"/>
              </div>
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="addingLocation" color="primary">Add</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>
<script>
import { scroll } from 'quasar'
import filterSelect from 'components/filterSelect'
import HeaderBasic from 'components/managePlatform/HeaderBasic'

const { getScrollTarget, setScrollPosition } = scroll

export default {
  components: { HeaderBasic, filterSelect },
  data () {
    return {
      department: null,
      departmentTitle: '',
      departmentModal: false,
      addingDepartment: false,
      deletingDepartment: false,

      locationType: null,
      locationTypeTitle: '',
      locationTypeModal: false,
      addingLocationType: false,
      deletingLocationType: false,

      location: {
        name: null,
        city: null,
        state: null,
        parent_uid: null,
        nest_type_uid: null
      },
      editLocation: null,
      locationModal: false,
      savingLocation: false,
      addingLocation: false,
      deletingLocation: false,
      normalizer (node) {
        return {
          id: node.uid,
          label: node.name,
          children: node.children
        }
      }
    }
  },
  computed: {
    formRules: function () {
      return {
        title: [ this.$formRules.required('Title') ],
        name: [ this.$formRules.required('Name') ],
        city: [ this.$formRules.required('City') ],
        state: [ this.$formRules.required('State') ],
        parentLocation: [ this.$formRules.required('Parent Location') ],
        locationType: [ this.$formRules.required('Location Type') ]
      }
    }
  },
  watch: {
    'departmentModal' (val) {
      if (!val) this.departmentTitle = ''
    },
    'locationTypeModal' (val) {
      if (!val) this.locationTypeTitle = ''
    },
    'locationModal' (val) {
      this.clearLocation()
    },
    editLocation (val) {
      if (val) this.selectLocation()
    }
  },
  methods: {
    addDepartment () {
      this.addingDepartment = true
      this.$store.dispatch('departments/add', {
        title: this.departmentTitle
      }).then((department) => {
        this.addingDepartment = false
        this.departmentModal = false
        this.$successMsg()
      }).catch((e) => {
        this.addingDepartment = false
        this.$failureMsg()
      })
    },
    deleteDepartment () {
      this.deletingDepartment = true
      this.$store.dispatch('departments/remove', this.department).then(() => {
        this.department = null
        this.deletingDepartment = false
        this.$successMsg()
      }).catch((e) => {
        this.deletingDepartment = false
        this.$failureMsg()
      })
    },
    addLocationType () {
      this.addingLocationType = true
      this.$store.dispatch('locationTypes/add', this.locationTypeTitle).then((resp) => {
        this.locationTypeModal = false
        this.addingLocationType = false
        this.$successMsg()
      }).catch((e) => {
        this.addingLocationType = false
        this.$failureMsg()
      })
    },
    deleteLocationType () {
      this.deletingLocationType = true
      this.$store.dispatch('locationTypes/remove', this.locationType).then(() => {
        this.deletingLocationType = false
        this.locationType = null
        this.$successMsg()
      }).catch((e) => {
        this.deletingLocationType = false
        this.$failureMsg(e.message)
      })
    },
    clearLocation () {
      this.location = {
        name: null,
        city: null,
        state: null,
        parent_uid: null,
        nest_type_uid: null
      }
    },
    addLocation () {
      // temporary until we can handle deep nested locations (customers) better
      // this.location.parent_uid = this.$store.getters['auth/company'].uid

      this.addingLocation = true
      this.location.type = _.find(this.$store.getters['locationTypes/list'], ['value', this.location.nest_type_uid]).label
      this.$store.dispatch('locations/add', this.location).then((resp) => {
        this.$successMsg()
        this.addingLocation = false
        this.locationModal = false
        this.clearLocation()
      }).catch((e) => {
        this.$failureMsg()
        this.addingLocation = false
      })
    },
    updateLocation () {
      // temporary until we can handle deep nested locations (customers) better
      // this.location.parent_uid = this.$store.getters['auth/company'].uid

      this.savingLocation = true
      this.location.type = _.find(this.$store.getters['locationTypes/list'], ['value', this.location.nest_type_uid]).label
      this.$store.dispatch('locations/update', this.location).then((resp) => {
        this.$successMsg()
        this.savingLocation = false
        this.editLocation = null
        this.clearLocation()
      }).catch((e) => {
        this.savingLocation = false
        this.$failureMsg()
      })
    },
    selectLocation () {
      this.location = this.$store.getters['locations/getByUid'](this.editLocation)

      if (this.location) {
        setTimeout(() => {
          let el = document.getElementById('editLocation')
          setScrollPosition(getScrollTarget(el), el.offsetTop, 500)
        }, 100)
      }
    },
    async deleteLocation (locationUid) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete this location? All users assigned to this location will be reassigned to its parent`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(async () => {
        this.deletingLocation = true
        this.$q.loading.show()
        try {
          await this.$store.dispatch('locations/remove', locationUid)
          this.$successMsg('Deleted Location')
          this.deletingLocation = false
          this.$q.loading.hide()
          this.editLocation = null
          this.clearLocation()
        } catch (e) {
          this.$failureMsg()
          this.deletingLocation = false
          this.$q.loading.hide()
        }
      })
    }
  },
  async created () {
    this.$store.dispatch('locations/fetch')
    this.$store.dispatch('departments/fetch')
    this.$store.dispatch('locationTypes/fetch')
    this.states = _.sortBy(this.$consts.states, ['label'])
  }
}
</script>
